import React, { useEffect } from "react"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/SEO"

/*
import CookieBot from "../components/cookiebot/CookieBot"
*/

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "4rem",
  },
}))

const CookiesPage = () => {
  /*
  const classes = useStyles()
  const domainGroupId = '0d366142-2563-458d-8a44-7a3849b17ad8'
  return (
    <Layout>
      <Container className={classes.container}>
        <CookieBot domainGroupId={domainGroupId} />
      </Container>
    </Layout>
  )
  */

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://consent.cookiebot.com/0d366142-2563-458d-8a44-7a3849b17ad8/cd.js";
    script.async = true;
    script.id = "CookieDeclaration";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Cookies" />
      <h1>Cookies</h1>
      <Container className={classes.container}>
      </Container>
    </Layout>
  )
}

export default CookiesPage